import { GlobalProgress } from '@/components/global-progress'
import { Toaster } from '@/components/ui/sonner'
import { Analytics, CookieBanner } from '@/lib/analytics'
import { cn, getDomainUrl } from '@/lib/utils'
import '@/styles/tailwind.css'
import interUrl from '@fontsource-variable/inter?url'
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useMatch,
} from '@remix-run/react'
import { GeneralErrorBoundary } from './components/general-error-boundary'

export const shouldRevalidate = () => false

export async function loader({ request }: LoaderFunctionArgs) {
  await import('@fuse/core/db')
  return json(
    { baseUrl: getDomainUrl(request) },
    {
      headers: {
        'Cache-Control': 'public, stale-while-revalidate=604800, max-age=300',
      },
    },
  )
}

export const links: LinksFunction = () => [
  {
    rel: 'preload',
    href: interUrl,
    as: 'style',
  },
  {
    rel: 'stylesheet',
    href: interUrl,
  },
]

const description = 'A better way to share your work. Easy. Secure. Beautiful.'

export const meta: MetaFunction<typeof loader> = ({ data }) => [
  // title tags
  { title: 'fuse.space' },
  { property: 'og:title', content: 'fuse.space' },
  // description tags
  {
    name: 'description',
    content: description,
  },
  {
    name: 'twitter:description',
    content: description,
  },
  {
    property: 'og:description',
    content: description,
  },
  // image tags
  { name: 'twitter:image', content: '/og-image.jpg' },
  { property: 'og:image', content: '/og-image.jpg' },
  // site tags
  { property: 'og:url', content: data?.baseUrl },
]

export function Layout() {
  Analytics.useTrackPageview()

  return (
    <html
      lang='en'
      className={cn('dark font-sans', useMatch('/') && 'scroll-smooth')}
    >
      <head>
        <meta charSet='utf-8' />
        <meta name='color-scheme' content='dark' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta property='og:site_name' content='fuse.space' />
        <meta property='og:locale' content='en_US' />
        <Meta />
        <Links />
      </head>
      <body>
        <ScrollRestoration />
        <Scripts />
        <Analytics.Scripts />
        <Outlet />
        <Toaster duration={5000} />
        <GlobalProgress />
        <CookieBanner />
      </body>
    </html>
  )
}

export const ErrorBoundary = GeneralErrorBoundary

export default function Root() {
  return <Outlet />
}
