import { cn } from '@/lib/utils'
import { useNavigation } from '@remix-run/react'
import { useEffect, useRef, useState } from 'react'
import { useSpinDelay } from 'spin-delay'

const noProgress = [{ formAction: '/app/pages/new', formMethod: 'POST' }]

function shouldHide(transition?: { formAction?: string; formMethod?: string }) {
  const match = noProgress.find(
    (t) =>
      t.formAction === transition?.formAction &&
      t.formMethod === transition?.formMethod,
  )
  return !!match
}

export function GlobalProgress() {
  const transition = useNavigation()
  const busy = !shouldHide(transition) && transition.state !== 'idle'
  const delayedPending = useSpinDelay(busy, {
    delay: 600,
    minDuration: 400,
  })

  const ref = useRef<HTMLDivElement>(null)
  const [animationComplete, setAnimationComplete] = useState(true)

  useEffect(() => {
    if (!ref.current) return
    if (delayedPending) setAnimationComplete(false)

    const animationPromises = ref.current
      .getAnimations()
      .map(({ finished }) => finished)

    Promise.allSettled(animationPromises).then(() => {
      if (!delayedPending) setAnimationComplete(true)
    })
  }, [delayedPending])

  return (
    <div
      role='progressbar'
      aria-hidden={delayedPending ? undefined : true}
      aria-valuetext={delayedPending ? 'Loading' : undefined}
      className='fixed inset-x-0 left-0 top-0 z-50 h-[0.20rem] animate-pulse'
    >
      <div
        ref={ref}
        className={cn(
          'h-full w-0 bg-foreground duration-500 ease-in-out',
          transition.state === 'idle' &&
            (animationComplete
              ? 'transition-none'
              : 'w-full opacity-0 transition-all'),
          delayedPending && transition.state === 'submitting' && 'w-5/12',
          delayedPending && transition.state === 'loading' && 'w-8/12',
        )}
      />
    </div>
  )
}
