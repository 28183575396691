import { Button } from '@/components/ui/button'
import { DialogFooter } from '@/components/ui/dialog'
import { Google } from '@/lib/analytics/google'
import { Posthog } from '@/lib/analytics/posthog'
import { Cross2Icon } from '@radix-ui/react-icons'
import { Link } from '@remix-run/react'
import * as React from 'react'
import { z } from 'zod'

const consentSchema = z
  .enum(['undecided', 'yes', 'no'])
  .default('undecided')
  .catch('undecided')

export type CookieConsent = z.infer<typeof consentSchema>

function getConsent() {
  return consentSchema.parse(localStorage.getItem('cookie-consent'))
}

export function CookieBanner() {
  const [consent, setConsent] = React.useState<CookieConsent>()

  React.useEffect(() => {
    setConsent(getConsent())
  }, [])

  React.useEffect(() => {
    if (consent) {
      Google.setConsent(consent)
      Posthog.setConsent(consent)
    }
  }, [consent])

  const handleAcceptCookies = () => {
    localStorage.setItem('cookie-consent', 'yes')
    setConsent('yes')
  }

  const handleDeclineCookies = () => {
    localStorage.setItem('cookie-consent', 'no')
    setConsent('no')
  }

  if (!consent || consent !== 'undecided') {
    return null
  }

  // TODO: add the appropriate aria attributes for this to appear as a dialog
  return (
    <footer className='fixed z-50 bottom-0 left-0 right-0 sm:bottom-4 sm:left-4 sm:max-w-md border bg-background p-6 shadow-lg rounded-lg'>
      <p className='text-sm'>
        By clicking “Accept Cookies”, you agree to the storing of cookies on
        your device to enhance site navigation, analyze site usage, and assist
        in our marketing efforts. View our{' '}
        <Link to='/terms' target='_blank' className='underline'>
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link to='/privacy' target='_blank' className='underline'>
          Privacy Policy
        </Link>{' '}
        for more information.
      </p>

      <Button
        variant='transparent'
        size='content'
        type='button'
        onClick={handleDeclineCookies}
        className='absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
      >
        <Cross2Icon className='h-4 w-4' />
        <span className='sr-only'>Close</span>
      </Button>

      <DialogFooter className='mt-4'>
        <Button variant='outline' onClick={handleDeclineCookies}>
          Decline
        </Button>
        <Button onClick={handleAcceptCookies} autoFocus>
          Accept Cookies
        </Button>
      </DialogFooter>
    </footer>
  )
}
